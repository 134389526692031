import { BASE_CLASS_PREFIX } from '../base/constants';

const cssClasses = {
    PREFIX: `${BASE_CLASS_PREFIX}-table`,
    TABLE: `${BASE_CLASS_PREFIX}-table`,
    WRAP: `${BASE_CLASS_PREFIX}-table-wrapper`,
    THEAD: `${BASE_CLASS_PREFIX}-table-thead`,
    TR: `${BASE_CLASS_PREFIX}-table-row`,
    TH: `${BASE_CLASS_PREFIX}-table-row-head`,
    TD: `${BASE_CLASS_PREFIX}-table-row-cell`,
} as const;

const strings = {
    SIZE_SMALL: 'small',
    SIZE_MIDDLE: 'middle',
    SIZES: ['small', 'default', 'middle'],
    LAYOUTS: ['', 'auto', 'fixed'],
    PAGINATION_POSITIONS: ['bottom', 'top', 'both'],
    FOOTER_POSITIONS: [false, true, 'left', 'right'],
    SORT_DIRECTIONS: ['ascend', 'descend'],
    FIXED_SET: [false, true, 'left', 'right'],
    ALIGNS: ['left', 'right', 'center'],
    SCROLL_HORIZONTAL_POSITIONS: ['left', 'middle', 'right'],
    DEFAULT_KEY_COLUMN_SELECTION: 'column-selection',
    DEFAULT_KEY_COLUMN_EXPAND: 'column-expand',
    DEFAULT_KEY_COLUMN_TITLE: 'column-title',
    DEFAULT_KEY_COLUMN_SORTER: 'column-sorter',
    DEFAULT_KEY_COLUMN_FILTER: 'column-filter',
    DEFAULT_KEY_COLUMN_SCROLLBAR: 'column-scrollbar',
    DEFAULT_COMPONENTS: {
        table: 'table',
        header: {
            outer: 'table',
            wrapper: 'thead',
            row: 'tr',
            cell: 'th',
        },
        body: {
            outer: 'table',
            wrapper: 'tbody',
            row: 'tr',
            cell: 'td',
        },
        footer: {
            wrapper: 'tfoot',
            row: 'tr',
            cell: 'td',
        },
    },
    EXPAND_RELATED_PROPS: ['expandedRowRender', 'dataSource', 'hideExpandedColumn', 'childrenRecordName', 'rowExpandable'],
} as const;

const DEFAULT_CELL_BORDER_WITH_BOTTOM = 1;
const DEFAULT_CELL_BORDER_WITH_TOP = 0;
const DEFAULT_CELL_PADDING_TOP = 16;
const DEFAULT_CELL_PADDING_BOTTOM = 16;
const DEFAULT_CELL_MIDDLE_PADDING_TOP = 12;
const DEFAULT_CELL_MIDDLE_PADDING_BOTTOM = 12;
const DEFAULT_CELL_SMALL_PADDING_TOP = 8;
const DEFAULT_CELL_SMALL_PADDING_BOTTOM = 8;
const DEFAULT_CELL_LINE_HEIGHT = 20;

// normal size
const DEFAULT_VIRTUALIZED_ROW_HEIGHT =
    DEFAULT_CELL_LINE_HEIGHT +
    DEFAULT_CELL_BORDER_WITH_BOTTOM +
    DEFAULT_CELL_BORDER_WITH_TOP +
    DEFAULT_CELL_PADDING_TOP +
    DEFAULT_CELL_PADDING_BOTTOM;
const DEFAULT_VIRTUALIZED_ROW_MIN_HEIGHT =
    DEFAULT_CELL_PADDING_TOP + DEFAULT_CELL_PADDING_BOTTOM + DEFAULT_CELL_BORDER_WITH_BOTTOM;

// middle  size
const DEFAULT_VIRTUALIZED_ROW_MIDDLE_HEIGHT =
    DEFAULT_CELL_LINE_HEIGHT +
    DEFAULT_CELL_BORDER_WITH_BOTTOM +
    DEFAULT_CELL_BORDER_WITH_TOP +
    DEFAULT_CELL_MIDDLE_PADDING_TOP +
    DEFAULT_CELL_MIDDLE_PADDING_BOTTOM;
const DEFAULT_VIRTUALIZED_ROW_MIDDLE_MIN_HEIGHT =
    DEFAULT_CELL_MIDDLE_PADDING_TOP + DEFAULT_CELL_MIDDLE_PADDING_BOTTOM + DEFAULT_CELL_BORDER_WITH_BOTTOM;

// small size
const DEFAULT_VIRTUALIZED_ROW_SMALL_HEIGHT =
    DEFAULT_CELL_LINE_HEIGHT +
    DEFAULT_CELL_BORDER_WITH_BOTTOM +
    DEFAULT_CELL_BORDER_WITH_TOP +
    DEFAULT_CELL_SMALL_PADDING_BOTTOM +
    DEFAULT_CELL_SMALL_PADDING_TOP;
const DEFAULT_VIRTUALIZED_ROW_SMALL_MIN_HEIGHT =
    DEFAULT_CELL_SMALL_PADDING_TOP + DEFAULT_CELL_SMALL_PADDING_BOTTOM + DEFAULT_CELL_BORDER_WITH_BOTTOM;

const numbers = {
    DEFAULT_PAGE_SIZE: 10,
    DEFAULT_WIDTH_COLUMN_EXPAND: 60,
    DEFAULT_WIDTH_COLUMN_SELECTION: 60,
    DEFAULT_INDENT_WIDTH: 20,
    DEFAULT_CELL_PADDING_LEFT: 16,
    DEFAULT_CELL_PADDING_RIGHT: 16,
    DEFAULT_CELL_PADDING_TOP,
    DEFAULT_CELL_PADDING_BOTTOM,
    DEFAULT_CELL_MIDDLE_PADDING_TOP,
    DEFAULT_CELL_MIDDLE_PADDING_BOTTOM,
    DEFAULT_CELL_SMALL_PADDING_TOP,
    DEFAULT_CELL_SMALL_PADDING_BOTTOM,
    DEFAULT_CELL_BORDER_WIDTH_LEFT: 1,
    DEFAULT_CELL_BORDER_WIDTH_RIGHT: 1,
    DEFAULT_CELL_BORDER_WITH_BOTTOM,
    DEFAULT_CELL_LINE_HEIGHT,
    DEFAULT_VIRTUALIZED_BODY_HEIGHT: 600,
    DEFAULT_VIRTUALIZED_SECTION_ROW_HEIGHT: DEFAULT_VIRTUALIZED_ROW_HEIGHT,
    DEFAULT_VIRTUALIZED_ROW_HEIGHT,
    DEFAULT_VIRTUALIZED_ROW_MIN_HEIGHT,
    DEFAULT_VIRTUALIZED_SECTION_ROW_MIDDLE_HEIGHT: DEFAULT_VIRTUALIZED_ROW_MIDDLE_HEIGHT,
    DEFAULT_VIRTUALIZED_ROW_MIDDLE_HEIGHT,
    DEFAULT_VIRTUALIZED_ROW_MIDDLE_MIN_HEIGHT,
    DEFAULT_VIRTUALIZED_SECTION_ROW_SMALL_HEIGHT: DEFAULT_VIRTUALIZED_ROW_SMALL_HEIGHT,
    DEFAULT_VIRTUALIZED_ROW_SMALL_HEIGHT,
    DEFAULT_VIRTUALIZED_ROW_SMALL_MIN_HEIGHT,
} as const;

export { cssClasses, strings, numbers };
